<template>
  <div id="page-index">
    <a-spin/>
  </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { computed } from "@vue/reactivity";
import { onMounted, unref } from "@vue/runtime-core";

export default {
  name: "IndexPage",
  setup() {
    const route = useRoute();
    const store = useStore();
    const router = useRouter();

    const role = computed(() => store.state.cabinet.role);
    const routePath = computed(() => route?.path || '');

    onMounted(async () => {
      if (unref(role) === 'admin' && unref(routePath)?.indexOf('/admin') >= 0) {
        router.push({ name: 'objects' });
      } else {
        router.push({ name: 'myReservations' });
      }
    });

    return {};
  },
}
</script>
